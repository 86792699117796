import React, { FunctionComponent, ReactElement } from "react";
import { IconButton, useDisclosure } from "@chakra-ui/react";
import { SettingsIcon } from "@chakra-ui/icons";

import HeatingControlModal from "./Modal";


interface IProps {
  siteId: string;
  icon?: ReactElement;
}


const HeatingSettings: FunctionComponent<IProps> = ({
  siteId,
  icon = <SettingsIcon />,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return <>
    {isOpen && (
      <HeatingControlModal
        siteId={siteId}
        onClose={onClose}
      />
    )}

    <IconButton
      aria-label="Settings"
      colorScheme="yellow"
      size="sm"
      onClick={onOpen}
      icon={icon}
    />
  </>
}

export default HeatingSettings;
