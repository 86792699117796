import React, { FunctionComponent, useEffect, useState, useCallback } from "react";
import { HStack } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { TimeIcon } from "@chakra-ui/icons";
import { useApolloClient } from "@apollo/client";

import {
  SiteConfig_siteConfig_manualControlConf,
  SetModesState,
  SetModesStateVariables,
  SiteConfig_siteConfig_modesConf,
} from "graphql/generated";
import { SET_MODES_STATE } from "graphql/mutations";

import { snakeToCamelCase } from "common/stringUtils";
import { useToasts } from "common/toasts";
import PowerControlSettings from "./power-control-settings";
import HeatingSettings from "./heating-settings";
import ControlSwitch from "./ControlSwitch";

const MODE_KEY = [
  "manual",
  "peak_shaving",
  "timed_peak",
  "load_leveling",
  "offgrid",
  "heating",
  "blocking",
] as const;

interface IProps {
  siteId: string;
  modesConf: SiteConfig_siteConfig_modesConf;
  manualControlConf: SiteConfig_siteConfig_manualControlConf;
  state: any;
}

const Controls: FunctionComponent<IProps> = ({
  siteId,
  modesConf,
  manualControlConf,
  state,
}) => {
  const intl = useIntl();
  const client = useApolloClient();
  const { pushSuccessToast, pushErrorToast } = useToasts();
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(false);
  }, [state]);

  const setModeValue = (modeKey: typeof MODE_KEY[number], checked: boolean) => {
    setLoading(true);

    const mutationState = Object.fromEntries(
      MODE_KEY.map((x) => [snakeToCamelCase(x), state[x]?.is_on || false])
    );
    mutationState[snakeToCamelCase(modeKey)] = checked;

    client
      .mutate<SetModesState, SetModesStateVariables>({
        mutation: SET_MODES_STATE,
        variables: { siteId, state: mutationState },
      })
      .then((res) => {
        if (res.data) {
          pushSuccessToast(
            intl.formatMessage({
              defaultMessage: "Successfully saved",
              id: "site__dashboard__controls__save_success",
            })
          );
        } else {
          pushErrorToast(String(res.errors));
        }
      })
      .catch((reason) => {
        pushErrorToast(String(reason));
        setLoading(false);
      });
  };

  const loadLevelingLabel = useCallback(() => {
    switch(modesConf.peakShavingUiVersion) {
      case "neufe": return intl.formatMessage({
        defaultMessage: "Automat", // TODO this very likely isn't an English word
        id: "site__dashboard__controls__neufe-automat",
      })
      default: return intl.formatMessage({
        defaultMessage: "Load leveling",
        id: "site__dashboard__controls__load-leveling",
      })
    }
  }, [modesConf, intl])

  return (
    <>
      <HStack spacing={8}>
        {state.manual && (
        <ControlSwitch
          id="manual-control"
          title={intl.formatMessage({
            defaultMessage: "Manual control",
            id: "site__dashboard__controls__manual-control",
          })}
          isChecked={state.manual?.is_on || false}
          isEnabled={state.manual?.is_enabled || false}
          isAllowed={!!modesConf.manualUiVersion}
          isLoading={isLoading}
          onSwitch={(checked) => {
            setModeValue("manual", checked);
          }}
        >
          <PowerControlSettings
            siteId={siteId}
            manualControlConf={manualControlConf}
          />
        </ControlSwitch>
        )}

        {state.blocking && (
        <ControlSwitch
          id="blocking"
          title={intl.formatMessage({
            defaultMessage: "Blocking",
            id: "site__dashboard__controls__blocking",
          })}
          isChecked={state.blocking?.is_on || false}
          isEnabled={state.blocking?.is_enabled || false}
          isAllowed={!!modesConf.blockingUiVersion}
          isLoading={isLoading}
          checkedHighlight="red.500"
          onSwitch={(checked) => {
            setModeValue("blocking", checked);
          }}
        />
        )}

        {state.peak_shaving && (
        <ControlSwitch
          id="peak-shaving"
          title={intl.formatMessage({
            defaultMessage: "Peak shaving",
            id: "site__dashboard__controls__peak-shaving",
          })}
          isChecked={state.peak_shaving?.is_on || false}
          isEnabled={state.peak_shaving?.is_enabled || false}
          isAllowed={!!modesConf.peakShavingUiVersion}
          isLoading={isLoading}
          onSwitch={(checked) => {
            setModeValue("peak_shaving", checked);
          }}
        />
        )}

        {state.timed_peak && (
        <ControlSwitch
          id="timed-peak"
          title={intl.formatMessage({
            defaultMessage: "Timed peak shaving",
            id: "site__dashboard__controls__timed-peak",
          })}
          isChecked={state.timed_peak?.is_on || false}
          isEnabled={state.timed_peak?.is_enabled || false}
          isAllowed={!!modesConf.timedPeakUiVersion}
          isLoading={isLoading}
          onSwitch={(checked) => {
            setModeValue("timed_peak", checked);
          }}
        />
        )}

        {state.offgrid && (
        <ControlSwitch
          id="offgrid-mode"
          title={intl.formatMessage({
            defaultMessage: "Off-grid mode",
            id: "site__dashboard__controls__offgrid-mode",
          })}
          isChecked={state.offgrid?.is_on || false}
          isEnabled={state.offgrid?.is_enabled || false}
          isAllowed={!!modesConf.offgridUiVersion}
          isLoading={isLoading}
          onSwitch={(checked) => {
            setModeValue("offgrid", checked);
          }}
        />
        )}

        {state.load_leveling && (
        <ControlSwitch
          id="load-leveling"
          title={loadLevelingLabel()}
          isChecked={state.load_leveling?.is_on || false}
          isEnabled={state.load_leveling?.is_enabled || false}
          isAllowed={!!modesConf.loadLevelingUiVersion}
          isLoading={isLoading}
          onSwitch={(checked) => {
            setModeValue("load_leveling", checked);
          }}
        />
        )}

        {state.heating && (
        <ControlSwitch
          id="heating"
          title={intl.formatMessage({
            defaultMessage: "Heating",
            id: "site__dashboard__controls__heating",
          })}
          isChecked={state.heating?.is_on || false}
          isEnabled={state.heating?.is_enabled || false}
          isAllowed={!!modesConf.heatingUiVersion}
          isLoading={isLoading}
          onSwitch={(checked) => {
            setModeValue("heating", checked);
          }}
        >
          <HeatingSettings
            siteId={siteId}
            icon={<TimeIcon />}
          />
        </ControlSwitch>
        )}
      </HStack>
    </>
  );
};

export default Controls;
