import React, { FunctionComponent, useState } from "react";
import { useIntl } from "react-intl";
import {
  Button,
  FormLabel,
  FormControl,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { useApolloClient } from "@apollo/client";

import {
  GetManualControlValues_getManualControlValues,
  SetManualControlValues,
  SetManualControlValuesVariables,
  SiteConfig_siteConfig_manualControlConf,
} from "graphql/generated";
import { SET_MANUAL_CONTROL_VALUES_MUTATION } from "graphql/mutations";

import { useToasts } from "common/toasts";
import PowerControlSlider from "./PowerControlSlider";


interface IProps {
  siteId: string;
  values: GetManualControlValues_getManualControlValues;
  manualControlConf: SiteConfig_siteConfig_manualControlConf;
  onClose: () => void;
}


const ModalInside: FunctionComponent<IProps> = ({
  siteId,
  values,
  manualControlConf,
  onClose,
}) => {
  const intl = useIntl();
  const client = useApolloClient();
  const { pushSuccessToast, pushErrorToast } = useToasts();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [activePower, setActivePower] = useState<number>(values.activePower);
  const [reactivePower, setReactivePower] = useState<number>(
    values.reactivePower
  );

  return <>
      <ModalBody>
        <FormControl mb={6}>
          <FormLabel htmlFor="email">
            {intl.formatMessage({
              id: "modals__controls__active-power",
              defaultMessage: "Active power",
            })}
          </FormLabel>

          <PowerControlSlider
            max={manualControlConf.activePowerMax}
            value={activePower}
            setValue={setActivePower}
          />
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="email" mb={10}>
            {intl.formatMessage({
              id: "modals__controls__reactive-power",
              defaultMessage: "Reactive power",
            })}
          </FormLabel>

          <PowerControlSlider
            max={manualControlConf.reactivePowerMax}
            value={reactivePower}
            setValue={setReactivePower}
          />
        </FormControl>
      </ModalBody>

      <ModalFooter>
        <Button mr={3} onClick={onClose}>
          {intl.formatMessage({
            id: "modals__controls___close_button",
            defaultMessage: "Close",
          })}
        </Button>
        <Button
          colorScheme="blue"
          isDisabled={isLoading}
          onClick={() => {
            setLoading(true);
            client
              .mutate<SetManualControlValues, SetManualControlValuesVariables>({
                mutation: SET_MANUAL_CONTROL_VALUES_MUTATION,
                variables: { siteId, values: { activePower, reactivePower } },
              })
              .then(() => {
                pushSuccessToast(
                  intl.formatMessage({
                    id: "modals__controls__set_success",
                    defaultMessage: "Manual control values were successfully set",
                  })
                );
                setLoading(false);
                onClose();
              })
              .catch((reason) => {
                pushErrorToast(String(reason));
                setLoading(false);
              });
          }}
        >
          {intl.formatMessage({
            id: "modals__controls___apply_button",
            defaultMessage: "Apply",
          })}
        </Button>
      </ModalFooter>
  </>
}

export default ModalInside;
