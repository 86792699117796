import React, { FunctionComponent } from "react";
import {
  Badge,
  Flex,
  useColorModeValue,
  SpaceProps,
  SquareProps,
  Text,
} from "@chakra-ui/react";

interface IProps extends SpaceProps, SquareProps {
  title?: string;
  subTitle?: string;
  subTitleTooltip?: string;
}

const CardTitle: FunctionComponent<IProps> = ({
  title,
  subTitle,
  subTitleTooltip,
  children,
  ...rest
}) => {
  const titleBg = useColorModeValue("enposol.50", "enposol.900");
  const subTitleBg = useColorModeValue("gray.200", "gray.800");

  return (
    <Flex justify="space-between" align="flex-start" {...rest}>
      {title && (
        <Text
          fontSize={"sm"}
          fontWeight={500}
          bg={titleBg}
          p={2}
          px={3}
          color={"enposol.500"}
          roundedBottomRight="2xl"
        >
          {title}
        </Text>
      )}
      {subTitle && (
        <Badge
          bg={subTitleBg}
          title={subTitleTooltip}
        >
          {subTitle}
        </Badge>
      )}
      {children}
    </Flex>
  );
};

export default CardTitle;
