import React, { FunctionComponent } from "react";

import {
  useColorModeValue,
  Code,
} from "@chakra-ui/react";

import { controlReasonHumanLabel } from "../dashboard/utils";

import "./trading.scss";


interface IProps {
  reason?: string;
  timestamp?: string;
}


const PlcControlReason: FunctionComponent<IProps> = ({
  reason = "",
  timestamp = "",
}) => {
  return <>
    <Code
      ml={1}
      colorScheme={useColorModeValue("yellow", "yellow")}
      title={`${controlReasonHumanLabel(reason)} @ ${timestamp}`}
    >
      {reason}
    </Code>
  </>
}

export default PlcControlReason;
