import React, { FunctionComponent, useMemo, useState, useEffect, useCallback } from "react";
import omit from "ramda/src/omit";

import Consumers from "./Customers";
import { useQuery, useMutation } from "@apollo/client";

import {
  GetTradingSettings,
  GetTradingSettingsVariables,
} from "graphql/generated";
import { GET_TRADING_SETTINGS_QUERY } from "graphql/queries";
import { Heading } from "@chakra-ui/react";
import { SET_SETTINGS_MUTATION } from "graphql/mutations";
import { useToasts } from "common/toasts";
import { useIntl } from "react-intl";
import SettingsFormPage from "../../components/SettingsFormPage";

interface IProps {
  siteId: string;
  uiVersion?: string | null;
}

export interface ITradingConsumer {
  id: number;
  factory_id: number;
  title: string;
  price_heat: number;
  price_electro: number;
}

interface ITrading {
  consumers: ITradingConsumer[];
}


const Trading: FunctionComponent<IProps> = ({
  siteId,
  uiVersion,
}) => {
  const intl = useIntl();
  const { pushSuccessToast, pushErrorToast } = useToasts();

  const { data, loading } = useQuery<GetTradingSettings, GetTradingSettingsVariables>(
    GET_TRADING_SETTINGS_QUERY,
    {
      variables: { siteId },
      errorPolicy: "all",
    }
  );
  const [setSettings, { loading: isSaving }] = useMutation(SET_SETTINGS_MUTATION);
  const tradingData = useMemo(() => data?.getTradingSettings, [data]);

  const [trading, setTrading] = useState<ITrading>();

  const setConsumer = useCallback((index: number, consumer: ITradingConsumer) => {
    console.log("TOP", index, consumer)
    // if (trading) {
    //   trading.consumers[index] = consumer;
    //   setTrading({
    //     consumers: trading.consumers,
    //   });
    // }
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }

    setTrading({
      consumers: data.getTradingSettings.consumers.map(_ => omit(["__typename"], _) as unknown as ITradingConsumer),
    });
  }, [data]);

  return (
    <>
      <SettingsFormPage
        siteId={siteId}
        guideId="trading"
        uiVersion={uiVersion}
        settingsId="trading"
        settings={data?.getTradingSettings}
        isLoading={loading}
        items={[
          {
            id: "electricityBuyDistributionPrice",
            name: intl.formatMessage({
              id: "settings__trading__electricity__buy_distribution_price",
              defaultMessage: "Electricity; Distribution price",
            }),
            type: "float",
            unit: "€/MWh",
            decimals: 2,
          },
          {
            id: "electricityBuySystemServicesPrice",
            name: intl.formatMessage({
              id: "settings__trading__electricity__buy_system_services_price",
              defaultMessage: "Electricity; System services price",
            }),
            type: "float",
            unit: "€/MWh",
            decimals: 2,
          },
          {
            id: "electricityBuyRenewableEnergyPrice",
            name: intl.formatMessage({
              id: "settings__trading__electricity__buy_renewable_energy_price",
              defaultMessage: "Electricity; Renewable energy price",
            }),
            type: "float",
            unit: "€/MWh",
            decimals: 2,
          },
          {
            id: "electricityBuyEnergyTax",
            name: intl.formatMessage({
              id: "settings__trading__electricity__buy_tax",
              defaultMessage: "Electricity; Tax from electricity",
            }),
            type: "float",
            unit: "€/MWh",
            decimals: 2,
          },
          {
            id: "electricityOperatorFee",
            name: intl.formatMessage({
              id: "settings__trading__electricity__operator_fee",
              defaultMessage: "Electricity; Operator monthly fee",
            }),
            type: "float",
            unit: "€/m",
            decimals: 2,
          },
          {
            id: "electricityReservedCapacityFee",
            name: intl.formatMessage({
              id: "settings__trading__electricity__reserved_capacity_fee",
              defaultMessage: "Electricity; Reserved capacity monthly fee",
            }),
            type: "float",
            unit: "€/m",
            decimals: 2,
          },
          {
            id: "electricitySellFixedPrice",
            name: intl.formatMessage({
              id: "settings__trading__electricity__sell_fixed_price",
              defaultMessage: "Electricity; Fixed selling price",
            }),
            type: "float",
            unit: "€/MWh",
            decimals: 2,
          },
          {
            id: "electricityReservedCapacityBreachPenaltyPrice",
            name: intl.formatMessage({
              id: "settings__trading__electricity__reserved_capacity_breach_penalty_price",
              defaultMessage: "Electricity; Reserved capacity breach penalty price",
            }),
            type: "float",
            unit: "€/MW",
          },

          {
            id: "gasBuyDistributionPrice",
            name: intl.formatMessage({
              id: "settings__trading__gas__buy_distribution_price",
              defaultMessage: "Gas; Distribution price",
            }),
            type: "float",
            unit: "€/MWh",
            decimals: 2,
          },
          {
            id: "gasBuyAccountingPrice",
            name: intl.formatMessage({
              id: "settings__trading__gas__buy_accounting_price",
              defaultMessage: "Gas; Accounting price",
            }),
            type: "float",
            unit: "€/MWh",
            decimals: 2,
          },
          {
            id: "gasBuyEnergyTax",
            name: intl.formatMessage({
              id: "settings__trading__gas__buy_tax",
              defaultMessage: "Gas; Tax from gas",
            }),
            type: "float",
            unit: "€/MWh",
            decimals: 2,
          },
          {
            id: "gasOperatorFee",
            name: intl.formatMessage({
              id: "settings__trading__gas__operator_fee",
              defaultMessage: "Gas; Operator monthly fee",
            }),
            type: "float",
            unit: "€/m",
            decimals: 2,
          },
          {
            id: "gasBuyReservedCapacityFee",
            name: intl.formatMessage({
              id: "settings__trading__gas__buy_reserved_capacity_fee",
              defaultMessage: "Gas; Reserved capacity monthly fee",
            }),
            type: "float",
            unit: "€/m",
            decimals: 2,
          },
        ]}
      >
      {trading && (<>
        <Heading size="md" mt={6} mb={4}>
          {intl.formatMessage({
            id: "settings__trading__consumers___title",
            defaultMessage: "Consumers",
          })}
        </Heading>
        <Consumers
          uiVersion={uiVersion}
          data={trading.consumers}
          setConsumer={setConsumer}
          // settingsProps={settingsProps}
        />
      </>)}
      </SettingsFormPage>
    </>
  );
};

export default Trading;
