import React, { FunctionComponent } from "react";
import {
  Center,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { useQuery } from "@apollo/client";

import {
  GetHeatingSettings,
  GetHeatingSettingsVariables,
} from "graphql/generated";
import { GET_HEATING_CONTROL_VALUES_QUERY } from "graphql/queries";

import ModalInside from "./ModalInside";


interface IProps {
  siteId: string;
  onClose: () => void;
}


const HeatingControlModal: FunctionComponent<IProps> = ({
  siteId,
  onClose,
}) => {
  const intl = useIntl();

  const { data, loading } = useQuery<
    GetHeatingSettings,
    GetHeatingSettingsVariables
  >(GET_HEATING_CONTROL_VALUES_QUERY, { variables: { siteId } });

  return <>
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {intl.formatMessage({
            id: "modals__heating_settings____title",
            defaultMessage: "Heating settings",
          })}
        </ModalHeader>
        {!loading && <ModalCloseButton />}
        {data && !loading ? (
          <ModalInside
            siteId={siteId}
            settings={data.getHeatingSettings}
            onClose={onClose}
          />
        ) : (
          <Center mb={10} mt={8}>
            <Spinner size="xl" />
          </Center>
        )}
      </ModalContent>
    </Modal>
  </>
}

export default HeatingControlModal;
