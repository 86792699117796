import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

import {
  Box,
  Collapse,
  IconButton,
  Image,
  Stack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { SettingsIcon } from "@chakra-ui/icons";

import { SiteConfig_siteConfig_chpConf_chps } from "graphql/generated";

import Card from "components/card";
import CardTitle from "components/card-title";
import StatsTable from "components/stats-table";
import CHPState from "./CHPState";
import CHPSwitchState from "./CHPSwitchState";
import CHPUnitSettings from "./CHPUnitSettings";
import { displayId } from "common/stringUtils"


interface CHPUnitData {
  active_power: number;
  temp1: number;
  temp2: number;
  m3h: number;
  status: number;
  engine_hours: number;
  max_power: number;
}

interface IProps {
  siteId: string;
  chpId: number;
  config: SiteConfig_siteConfig_chpConf_chps;
  data: CHPUnitData;
}


export function getImageSrc(_data: CHPUnitData) {
  return `/images/svg/kogeneracni-jednotka.svg`;
}


const CHPUnitPreview: FunctionComponent<IProps> = ({
  siteId,
  chpId,
  config,
  data,
}) => {
  const intl = useIntl();
  const { isOpen: controlsOpen, onToggle: toggleControls } = useDisclosure();

  return (
    <Card>
      <CardTitle
        title={intl.formatMessage({
          id: "chp___title",
          defaultMessage: "CHP {id}"
        }, {
          id: displayId(chpId),
        })}
      >
        <IconButton
          aria-label="Settings"
          bg={useColorModeValue("white", "gray.800")}
          size="sm"
          m={1}
          onClick={toggleControls}
          icon={<SettingsIcon />}
          title={intl.formatMessage({
            id: "chp__settings",
            defaultMessage: "Settings",
          })}
        />
      </CardTitle>
      <Stack
        p={6}
        pt={0}
      >
        <Stack direction="row" w="full">
          <Box position="relative">
            <Image src={getImageSrc(data)} boxSize="150px" flex={1} />
          </Box>

          <Box flex={2}>
            <StatsTable
              inline
              rows={[
                {
                  label: intl.formatMessage({
                    id: "chp__active_power",
                    defaultMessage: "Active power",
                  }),
                  unit: "kW",
                  decimals: 1,
                  value: data?.active_power,
                },
                {
                  label: intl.formatMessage({
                    id: "chp__max_power",
                    defaultMessage: "Maximum power",
                  }),
                  unit: "kW",
                  decimals: 0,
                  value: config.max_power,
                },
                {
                  label: intl.formatMessage({
                    id: "chp__temp1",
                    defaultMessage: "Coolant",
                  }),
                  unit: "°C",
                  decimals: 1,
                  value: data?.temp1,
                },
                {
                  label: intl.formatMessage({
                    id: "chp__temp2",
                    defaultMessage: "Output water",
                  }),
                  unit: "°C",
                  decimals: 1,
                  value: data?.temp2,
                },
                {
                  label: intl.formatMessage({
                    id: "chp__switch",
                    defaultMessage: "Switch",
                  }),
                  value: <CHPSwitchState state={data?.m3h} />,
                },
                {
                  label: intl.formatMessage({
                    id: "chp__status",
                    defaultMessage: "Status",
                  }),
                  value: <CHPState state={data?.status} />,
                },
                {
                  label: intl.formatMessage({
                    id: "chp__engine_hours",
                    defaultMessage: "Engine hours",
                  }),
                  unit: "h",
                  decimals: 0,
                  value: data?.engine_hours,
                },
              ]}
            />
          </Box>
        </Stack>
      </Stack>
      <Collapse in={controlsOpen} unmountOnExit>
        <CHPUnitSettings
          siteId={siteId}
          chpId={chpId}
          toggle={toggleControls}
        />
      </Collapse>
    </Card>
  );
};

export default CHPUnitPreview;
