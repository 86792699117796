import React, { FunctionComponent, useState } from "react";
import { useIntl } from "react-intl";
import {
  Button,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { useApolloClient } from "@apollo/client";

import {
  GetHeatingSettings_getHeatingSettings,
  SetHeatingSettings,
  SetHeatingSettingsVariables,
} from "graphql/generated";
import { SET_HEATING_SETTINGS_MUTATION } from "graphql/mutations";

import { useToasts } from "common/toasts";
import HoursRangeSlider from "./HoursRangeSlider";


interface IProps {
  siteId: string;
  settings: GetHeatingSettings_getHeatingSettings;
  onClose: () => void;
}


const ModalInside: FunctionComponent<IProps> = ({
  siteId,
  settings,
  onClose,
}) => {
  const intl = useIntl();
  const client = useApolloClient();
  const { pushSuccessToast, pushErrorToast } = useToasts();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [workdayStart, setWorkdayStart] = useState<number>(settings.workdayStart);
  const [workdayEnd, setWorkdayEnd] = useState<number>(settings.workdayEnd);
  const [weekendStart, setWeekendStart] = useState<number>(settings.weekendStart);
  const [weekendEnd, setWeekendEnd] = useState<number>(settings.weekendEnd);

  return <>
    <ModalBody>

      <HoursRangeSlider
        label={intl.formatMessage({
          id: "modals__heating_settings__workday_heating",
          defaultMessage: "Work days",
        })}
        start={workdayStart}
        end={workdayEnd}
        setStart={setWorkdayStart}
        setEnd={setWorkdayEnd}
      />
      <HoursRangeSlider
        label={intl.formatMessage({
          id: "modals__heating_settings__weekend_heating",
          defaultMessage: "Weekend",
        })}
        start={weekendStart}
        end={weekendEnd}
        setStart={setWeekendStart}
        setEnd={setWeekendEnd}
      />

    </ModalBody>
    <ModalFooter>
      <Button mr={3} onClick={onClose}>
        {intl.formatMessage({
          id: "modals__heating_settings___close_button",
          defaultMessage: "Close",
        })}
      </Button>
      <Button
        colorScheme="blue"
        isDisabled={isLoading}
        onClick={() => {
          setLoading(true);
          client
            .mutate<SetHeatingSettings, SetHeatingSettingsVariables>({
              mutation: SET_HEATING_SETTINGS_MUTATION,
              variables: {
                siteId,
                settings: {
                  workdayStart,
                  workdayEnd,
                  weekendStart,
                  weekendEnd,
                },
              },
            })
            .then(() => {
              pushSuccessToast(
                intl.formatMessage({
                  id: "modals__heating_settings__set_success",
                  defaultMessage: "Heating settings were successfully set",
                })
              );
              setLoading(false);
              onClose();
            })
            .catch((reason) => {
              pushErrorToast(String(reason));
              setLoading(false);
            });
        }}
      >
        {intl.formatMessage({
          id: "modals__heating_settings___apply_button",
          defaultMessage: "Apply",
        })}
      </Button>
    </ModalFooter>
  </>
}

export default ModalInside;
