import React, { FunctionComponent, useCallback, ReactNode } from "react";
import { useIntl } from "react-intl";
import {
  Badge,
  Flex,
  FormLabel,
  FormControl,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderMark,
  RangeSliderThumb,
  RangeSliderTrack,
  useColorModeValue,
} from "@chakra-ui/react";


interface IProps {
  start: number;
  end: number;
  setStart: (value: number) => void;
  setEnd: (value: number) => void;
  label: string;
}


const HoursRangeSlider: FunctionComponent<IProps> = ({
    start,
    end,
    setStart,
    setEnd,
    label,
}) => {
  const intl = useIntl();

  const timeSpan = useCallback((start: number, end: number): ReactNode => {
    if (start !== end) {
      return <>
        {start}:00 - {end}:00
      </>
    } else {
      return <>
        <Badge colorScheme="red" variant="outline">
          {intl.formatMessage({
            id: "modals__heating_settings__heating_off",
            defaultMessage: "OFF",
          })}
        </Badge>
      </>
    }
  }, [intl]);

  return <>
    <FormControl mb={6}>
      <Flex justifyContent="space-between" alignItems="center">
        <FormLabel>{label}</FormLabel>
        {timeSpan(start, end)}
      </Flex>
      <RangeSlider
        defaultValue={[start, end]}
        min={0}
        max={24}
        step={1}
        onChange={(val) => {
            setStart(val[0])
            setEnd(val[1])
        }}
      >
      <RangeSliderMark value={6} mt={1} ml={-2} fontSize="sm">6</RangeSliderMark>
      <RangeSliderMark value={12} mt={1} ml={-2} fontSize="sm">12</RangeSliderMark>
      <RangeSliderMark value={18} mt={1} ml={-2} fontSize="sm">18</RangeSliderMark>
      <RangeSliderTrack bg={useColorModeValue("red.100","red.900")}>
        <RangeSliderFilledTrack bg="enposol.500" />
      </RangeSliderTrack>
      <RangeSliderThumb boxSize={6} index={0} />
      <RangeSliderThumb boxSize={6} index={1} />
      </RangeSlider>
    </FormControl>
  </>
}

export default HoursRangeSlider;
