
export const controlReasonHumanLabel = (reason: string | undefined): string => {
  switch (reason) {
    case "S0": return "plan scheduler service";
    case "S1+": return "reserved capacity stage; grid inflow reduction";
    case "S1-": return "reserved capacity stage; grid outflow reduction";
    case "S2": return "peak shaving stage; preparation for furnace run";
    case "S2+": return "peak shaving stage; power deficit";
    case "S2-": return "peak shaving stage; power excess";
    case "S3g": return "automat stage; gas heating";
    case "S3e": return "automat stage; electric heating";
    case "S3+": return "automat stage; enabled automat";
    case "S3-": return "automat stage; disabled automat";
    default: return ""
  }
}
