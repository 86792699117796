import React, { FunctionComponent, useRef, useEffect, useState } from "react";
import { Grid, GridItem, Box } from "@chakra-ui/react";
import assert from "assert";
import times from "ramda/src/times";
import splitEvery from "ramda/src/splitEvery";
import {
  curveBasis,
  line,
  easeLinear,
  select,
  Selection,
} from "d3";
import { debounce, isEqual } from "lodash"

import BatteryCard from "./cards/BatteryCard";
import ConverterCard from "./cards/ConverterCard";
import SuperchargerCard from "./cards/SuperchargerCard";
import SwitchCard from "./cards/SwitchCard";
import AtsCard from "./cards/AtsCard";
import PvCard from "./cards/PvCard";
import GridCard from "./cards/GridCard";
import LogoCard from "./cards/LogoCard";
import HVACCard from "./cards/HVACCard";
import CHPCard from "./cards/CHPCard";
import AnalyticsCard from "./cards/AnalyticsCard";
import WindTurbineCard from "./cards/WindTurbineCard";
import TransformerCard from "./cards/TransformerCard";
import FactoryCard from "./cards/FactoryCard";
import GensetCard from "./cards/GensetCard";
import FurnaceCard from "./cards/FurnaceCard";
import PaintShopCard from "./cards/PaintShopCard";
import HeatingCard from "./cards/HeatingCard";
import UnsupportedCard from "./cards/UnsupportedCard";
import {
  // DashboardMeasurement_dashboardMeasurement,
  SiteConfig_siteConfig_dashboardConf_components,
  SiteConfig_siteConfig_dashboardConf_connections,
} from "graphql/generated";


const CARD_ANCHOR_MARGIN = 0;


class Vector2D {
  constructor(public readonly x: number, public readonly y: number) {}

  squaredDist(other: Vector2D): number {
    return (this.x - other.x) ** 2 + (this.y - other.y) ** 2;
  }

  dist(other: Vector2D): number {
    return Math.sqrt(this.squaredDist(other));
  }
}

class GraphNode {
  public connections: Set<GraphNode> = new Set();

  constructor(public readonly coords: Vector2D) {}
}

class Graph {
  public nodes: Array<GraphNode> = [];

  registerNode(coords: Vector2D): GraphNode {
    const node = new GraphNode(coords);
    this.nodes.push(node);

    return node;
  }

  connect(a: GraphNode, b: GraphNode): Graph {
    assert(this.nodes.indexOf(a) >= 0);
    assert(this.nodes.indexOf(b) >= 0);

    a.connections.add(b);
    b.connections.add(a);

    return this;
  }

  getEdges(): Array<[GraphNode, GraphNode]> {
    const edges: Array<[GraphNode, GraphNode]> = [];
    for (const node of this.nodes) {
      node.connections.forEach((neighbor) => {
        const desiredEdge = edges.find(
          ([a, b]) =>
            (a === node && b === neighbor) || (b === node && a === neighbor)
        );

        if (!desiredEdge) {
          edges.push([node, neighbor]);
        }
      });
    }

    return edges;
  }

  getPath(start: GraphNode, target: GraphNode): Array<GraphNode> {
    assert(this.nodes.indexOf(start) >= 0);
    assert(this.nodes.indexOf(target) >= 0);

    const openSet = new Set<GraphNode>([start]);

    const parents = new Map<GraphNode, GraphNode>();
    const gScore = new Map<GraphNode, number>();
    const fScore = new Map<GraphNode, number>();

    for (const node of this.nodes) {
      gScore.set(node, Infinity);
      fScore.set(node, Infinity);
    }

    gScore.set(start, 0);
    fScore.set(start, start.coords.dist(target.coords));

    while (openSet.size > 0) {
      const aspirants: Array<[GraphNode, number]> = Array.from(
        openSet.values()
      ).map((x) => [x, fScore.get(x) || Infinity]);
      const [current] = aspirants.reduce((acc, x) => (x[1] < acc[1] ? x : acc));

      if (current === target) {
        let pathItem = current;
        const resultPath: Array<GraphNode> = [current];

        while (parents.has(pathItem)) {
          pathItem = parents.get(pathItem) as GraphNode;
          resultPath.push(pathItem);
        }

        return resultPath;
      }

      openSet.delete(current);

      current.connections.forEach((neighbor) => {
        const tentativeGScore =
          (gScore.get(current) || 0) + current.coords.dist(neighbor.coords);

        if (tentativeGScore < (gScore.get(neighbor) || 0)) {
          parents.set(neighbor, current);
          gScore.set(neighbor, tentativeGScore);
          fScore.set(
            neighbor,
            tentativeGScore + neighbor.coords.dist(target.coords)
          );
          openSet.add(neighbor);
        }
      });
    }

    return [];
  }
}


interface IGridCard {
  topLeft: Vector2D;
  size: Vector2D;
  topAnchor: GraphNode;
  bottomAnchor: GraphNode;
  leftAnchor: GraphNode;
  rightAnchor: GraphNode;
}

interface IConnection extends SiteConfig_siteConfig_dashboardConf_connections {
  index: number;
  flow: string;
  start: number;
  end: number;
  duration: number;
}

interface IFlows {
  [key: string]: string;
}

interface IWindowSize {
  width: number;
  height: number;
}

interface IProps {
  siteId: string;
  gridWidth: number;
  gridHeight: number;
  // cardsConfig: Partial<Record<string, ICardConfig>>;
  components: Array<SiteConfig_siteConfig_dashboardConf_components>;
  connections: Array<SiteConfig_siteConfig_dashboardConf_connections>;
  data: any;
  flowsData: IFlows;
}


const isFlowing = (flow: string): boolean => {
  return /.-to-./.test(flow);
}


const Measurements: FunctionComponent<IProps> = ({
  siteId,
  gridWidth,
  gridHeight,
  components,
  connections,
  data,
  flowsData = {},
}) => {
  const [flows, setFlows] = useState<IFlows>(flowsData);
  const [windowSize, setWindowSize] = useState<IWindowSize>({ width: 0, height: 0 });
  const containerRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);
  const flowsRef = useRef<Selection<any, any, any, any>>();
  const graphRef = useRef<Graph>(new Graph());
  const gridCardsRef = useRef<Array<Array<IGridCard>>>([]);


  // Compare incoming flows data with currently displayed
  //
  useEffect(() => {
    if (!isEqual(flowsData, flows)) {
      setFlows(flowsData);
    }
  }, [flowsData, flows, setFlows]);


  // Calculate the coordinates of cards and connection end points
  //
  useEffect(() => {
    const containerEl = containerRef.current;

    assert(containerEl);

    const graph = new Graph();
    const containerBr = containerEl.getBoundingClientRect();
    const cards = Array.from(containerEl.children).map((x) => {
      const itemBr = x.getBoundingClientRect();

      const topLeft = new Vector2D(
        itemBr.x - containerBr.x,
        itemBr.y - containerBr.y
      );
      const size = new Vector2D(itemBr.width, itemBr.height);

      return {
        topLeft,
        size,
        topAnchor: graph.registerNode(
          new Vector2D(
            topLeft.x + itemBr.width / 2,
            topLeft.y + CARD_ANCHOR_MARGIN
          )
        ),
        bottomAnchor: graph.registerNode(
          new Vector2D(
            topLeft.x + itemBr.width / 2,
            topLeft.y + itemBr.height - CARD_ANCHOR_MARGIN
          )
        ),
        leftAnchor: graph.registerNode(
          new Vector2D(
            topLeft.x + CARD_ANCHOR_MARGIN,
            topLeft.y + itemBr.height / 2
          )
        ),
        rightAnchor: graph.registerNode(
          new Vector2D(
            topLeft.x + itemBr.width - CARD_ANCHOR_MARGIN,
            topLeft.y + itemBr.height / 2
          )
        ),
      };
    });

    const gridCards: Array<Array<IGridCard>> = splitEvery(gridWidth, cards);
    const midNodes: Array<Array<GraphNode>> = [];

    for (let y = 0; y < gridHeight; y++) {
      const midNodesRow: Array<GraphNode> = [];

      midNodes.push(midNodesRow);

      for (let x = 0; x < gridWidth; x++) {
        let rightIntermediateNode = null;
        let bottomIntermediateNode = null;

        if (x < gridWidth - 1) {
          const currNode = gridCards[y][x].rightAnchor;
          const neighborNode = gridCards[y][x + 1].leftAnchor;
          const gapSize = neighborNode.coords.x - currNode.coords.x;
          rightIntermediateNode = graph.registerNode(
            new Vector2D(currNode.coords.x + 0.5 * gapSize, currNode.coords.y)
          );

          graph.connect(rightIntermediateNode, currNode);
          graph.connect(rightIntermediateNode, neighborNode);

          if (y > 0) {
            graph.connect(rightIntermediateNode, midNodes[y - 1][x]);
          }
        }

        if (y < gridHeight - 1) {
          const currNode = gridCards[y][x].bottomAnchor;
          const neighborNode = gridCards[y + 1][x].topAnchor;
          const gapSize = neighborNode.coords.y - currNode.coords.y;
          bottomIntermediateNode = graph.registerNode(
            new Vector2D(currNode.coords.x, currNode.coords.y + 0.5 * gapSize)
          );

          graph.connect(bottomIntermediateNode, currNode);
          graph.connect(bottomIntermediateNode, neighborNode);

          if (x > 0) {
            graph.connect(bottomIntermediateNode, midNodesRow[x - 1]);
          }
        }

        if (rightIntermediateNode && bottomIntermediateNode) {
          const midNode = graph.registerNode(
            new Vector2D(
              rightIntermediateNode.coords.x,
              bottomIntermediateNode.coords.y
            )
          );

          midNodesRow.push(midNode);

          graph.connect(midNode, rightIntermediateNode);
          graph.connect(midNode, bottomIntermediateNode);
        }
      }
    }

    gridCardsRef.current = gridCards;
    graphRef.current = graph;
  }, [gridWidth, gridHeight, windowSize]);


  // Create DOM paths per each connection and setup their shared properties.
  //
  useEffect(() => {
    const svgEl = select(svgRef.current);

    flowsRef.current = svgEl
      .selectAll(".dashboard-card-flow")
      .data( connections )
      .join("path")
        .attr("class", "dashboard-card-flow")
        ;
  }, [connections, flows]);


  // Drive the flows animations.
  //
  useEffect(() => {
    const animSpeed: number = 28; // px/s
    const animTime: number = 2; // s
    const animTravel: number = animSpeed * animTime; // px

    const isNodeDetached = (node: HTMLElement): boolean => {
      let parentNode: (Node & ParentNode) | null | null = node.parentNode;

      while (parentNode){
        parentNode = parentNode.parentNode;

        if (parentNode === document) return true;
        if (!parentNode) return false;
      }
      return false;
    }

    const dashAnimation = (index: number | null = null) => {
      assert(flowsRef.current);

      flowsRef.current
        .filter(function(_d, i: number) {
          const relevant: boolean = index === null || index === i;

          if (relevant && isNodeDetached(this)) return relevant;
          return false;
        })
        .each(function(d: IConnection, i: number) {
          d.index = index === null ? i : d.index;
          d.flow = flows[d.id] || d.defaultFlow;

          if (isFlowing(d.flow)) {
            d.end = (d.flow === "a-to-b" ? animTravel : -animTravel) / 2; // px

            const start: number = +select(this).attr("stroke-dashoffset"); // px
            const rest: number = Math.abs(d.end - start) / animTravel; // decimal 0-1

            d.start = rest > 0 ? start : -d.end; // px
            d.duration = rest > 0 ? rest * animTime : animTime; // s
          } else {
            d.start = d.end = 0; // px
            d.duration = animTime; // s
          }
        })
        .attr("stroke-dashoffset", ({ start }: IConnection) => start)
        .transition()
          .duration(({ duration }: IConnection) => duration * 1000)
          .ease(easeLinear)
          .attr("flow", ({ flow }: IConnection) => flow)
          .attr("stroke-dashoffset", ({ end }: IConnection) => end)
          .on("end", ({ index }: IConnection, i: number) => dashAnimation(index))
        ;
    }

    dashAnimation();
  }, [connections, flows]);


  // Draw each connection link curve in a direction according to connection's flow
  //
  useEffect(() => {
    assert(flowsRef.current);

    const curve = line().curve(curveBasis);
    const edgePointToGraphNode = (id: string, anchor: string) => {
      const cardConfig = components.find((x) => x.cardId === id);

      assert(cardConfig, `Unable to find edge point "${id}"`);

      const gridCard = gridCardsRef.current[cardConfig.y][cardConfig.x];

      switch (anchor) {
        case "top":
          return gridCard.topAnchor;

        case "bottom":
          return gridCard.bottomAnchor;

        case "left":
          return gridCard.leftAnchor;

        case "right":
          return gridCard.rightAnchor;

        default:
          throw new Error(
            `Unexpected card connection anchor "${anchor}" for card with id "${cardConfig.cardId}"`
          );
      }
    };

    flowsRef.current
      .sort(({ flow }: IConnection) => isFlowing(flow) ? 1 : -1 )
      .attr("flow", ({ flow }: IConnection) => flow )
      .attr("connection", ({ id }: IConnection) => id )
      .attr("d", ({ flow, componentA, componentB }: IConnection) => {
        const startNode = edgePointToGraphNode(componentA.id, componentA.anchor);
        const targetNode = edgePointToGraphNode(componentB.id, componentB.anchor);
        const path = graphRef.current.getPath(startNode, targetNode);

        return curve(path.map((node) => [node.coords.x, node.coords.y]));
      })
      ;
  }, [components, flows, windowSize]);


  // Resolve `windowSize` state and update it on window size change.
  //
  useEffect(() => {
    const containerEl = containerRef.current;
    assert(containerEl);

    const resizeWindow = () => {
      setWindowSize({
        width: containerEl.offsetWidth,
        height: containerEl.offsetHeight,
      });
    };
    resizeWindow();

    const debouncedResizeWindow = debounce(resizeWindow, 5, { leading: true, trailing: true });

    window.addEventListener('resize', debouncedResizeWindow);

    return () => {
      window.removeEventListener('resize', debouncedResizeWindow);
    }
  }, []);


  const baseUrl = `/site/${siteId}`;

  const getCardData = (
    cardConfig: SiteConfig_siteConfig_dashboardConf_components,
    sumBucketName: string,
    stashBucketNames: string[] = [],
  ): {[index: string]: string | number | boolean | null} | undefined => {
    const bucketName = cardConfig.id !== null ? `${cardConfig.cardId}_dig` : sumBucketName;

    if (data) {
      stashBucketNames.forEach((bucket: string) => {
        if (data[bucketName]) data[bucketName][bucket] = data[bucket];
      });

      return data[bucketName];
    } 
  }

  return (
    <Box position="relative">
      <svg
        id={"flowsViz"}
        width="100%"
        height="100%"
        viewBox={`0 0 ${windowSize.width} ${windowSize.height}`}
        preserveAspectRatio={"none"}
        ref={svgRef}
      />
      <Grid
        templateColumns={`repeat(${gridWidth}, 1fr)`}
        gap={20}
        ref={containerRef}
      >
        {times(
          (y) =>
            times((x) => {
              const cardInfo = Object.entries(components).find(
                ([id, config]) => config.x === x && config.y === y
              );
              let component = null;

              if (cardInfo) {
                const [, cardConfig] = cardInfo;

                switch (cardConfig.type) {
                  case "battery":
                    component = (
                      <BatteryCard
                        data={getCardData(cardConfig, "battery", ["battery_control"])}
                        baseUrl={baseUrl}
                        config={cardConfig}
                      />
                    );
                    break;

                  case "converter":
                    component = (
                      <ConverterCard
                        data={getCardData(cardConfig, "converters_sum")}
                        baseUrl={baseUrl}
                        config={cardConfig}
                      />
                    );
                    break;

                  case "supercharger":
                    component = (
                      <SuperchargerCard
                        data={getCardData(cardConfig, "chargers_sum")}
                        baseUrl={baseUrl}
                        config={cardConfig}
                      />
                    );
                    break;

                  case "switch":
                    component = (
                      <SwitchCard
                        data={getCardData(cardConfig, "switch")}
                        config={cardConfig}
                      />
                    );
                    break;

                  case "ats":
                    component = (
                      <AtsCard
                        data={getCardData(cardConfig, "ats")}
                        config={cardConfig}
                      />
                    );
                    break;

                  case "pv":
                    component = (
                      <PvCard
                        baseUrl={baseUrl}
                        data={getCardData(cardConfig, "pv_sum", ["pv_control"])}
                        config={cardConfig}
                      />
                    );
                    break;

                  case "grid":
                    component = (
                      <GridCard
                        data={getCardData(cardConfig, "grid")}
                        baseUrl={baseUrl}
                        config={cardConfig}
                      />
                    );
                    break;

                  case "logo":
                    component = <LogoCard />;
                    break;

                  case "chp":
                    component = (
                      <CHPCard
                        data={getCardData(cardConfig, "chp_sum", ["chp_control"])}
                        baseUrl={baseUrl}
                        config={cardConfig}
                      />
                    );
                    break;

                  case "hvac":
                    component = (
                      <HVACCard
                        data={getCardData(cardConfig, "hvac_sum")}
                        config={cardConfig}
                      />
                    );
                    break;

                  case "analytics":
                    assert(
                      cardConfig.chartData,
                      `Missing "chartData" for "analytics" card`
                    );
                    component = <AnalyticsCard chart={cardConfig.chartData} />;
                    break;

                  case "wind_turbine":
                    component = (
                      <WindTurbineCard
                        data={getCardData(cardConfig, "wt_sum")}
                        config={cardConfig}
                      />
                    );
                    break;

                  case "transformer":
                    component = (
                      <TransformerCard
                        data={getCardData(cardConfig, "transformers_sum")}
                        config={cardConfig}
                      />
                    );
                    break;

                  case "factory":
                    component = (
                      <FactoryCard
                        data={getCardData(cardConfig, "factory_sum")}
                        config={cardConfig}
                      />
                    );
                    break;

                  case "genset":
                    component = (
                      <GensetCard
                        data={getCardData(cardConfig, "gensets_sum")}
                        baseUrl={baseUrl}
                        config={cardConfig}
                      />
                    );
                    break;

                  case "furnace":
                    component = (
                      <FurnaceCard
                        data={getCardData(cardConfig, "furnaces_sum")}
                        baseUrl={baseUrl}
                        config={cardConfig}
                      />
                    );
                    break;

                  case "paint_shop":
                    component = (
                      <PaintShopCard
                        data={getCardData(cardConfig, "ps_sum")}
                        baseUrl={baseUrl}
                        config={cardConfig}
                      />
                    );
                    break;

                  case "heating":
                    component = (
                      <HeatingCard
                        data={getCardData(cardConfig, "heating_sum", ["heating_control"])}
                        baseUrl={baseUrl}
                        config={cardConfig}
                      />
                    );
                    break;

                  default:
                    component = (
                      <UnsupportedCard
                        config={cardConfig}
                      />
                    );
                }
              }

              return (
                <GridItem w="100%" key={`${x}_${y}`}>
                  {component}
                </GridItem>
              );
            }, gridWidth),
          gridHeight
        )}
      </Grid>
    </Box>
  );
};

export default Measurements;
