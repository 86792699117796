import { gql } from "@apollo/client";


export const LOGIN_MUTATION = gql`
  mutation LoginMutation($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      id
      token
      username
      email
      role
      organization {
        id
        name
      }
      homesiteId
      preferences {
        ui__dark_mode
        ui__locale
      }
    }
  }
`;

export const SET_MODES_STATE = gql`
  mutation SetModesState($siteId: ID!, $state: IModesStateInput!) {
    setModesState(siteId: $siteId, state: $state)
  }
`;

export const SET_HVAC_CONTROLS = gql`
  mutation SetHvacControls(
    $siteId: ID!
    $hvacId: Int!
    $controls: IHvacControlsInput!
  ) {
    setHvacControls(siteId: $siteId, hvacId: $hvacId, controls: $controls)
  }
`;

export const SET_HEATING_TANK_SETTINGS = gql`
  mutation SetHeatingTankSettings(
    $siteId: ID!
    $tankId: Int!
    $settings: IHeatingTankSettingsInput!
  ) {
    setHeatingTankSettings(siteId: $siteId, tankId: $tankId, settings: $settings)
  }
`;

export const SET_CHP_UNIT_SETTINGS = gql`
  mutation SetChpSettings(
    $siteId: ID!
    $chpId: Int!
    $settings: IChpSettingsInput!
  ) {
    setChpSettings(siteId: $siteId, chpId: $chpId, settings: $settings)
  }
`;

export const SET_PEAK_SHAVING_TIME_TABLE = gql`
  mutation SetPeakShavingTimeTable(
    $siteId: ID!
    $table: IPeakShavingTimeTableInput!
  ) {
    setPeakShavingTimeTable(siteId: $siteId, table: $table)
  }
`;

export const SET_MANUAL_CONTROL_VALUES_MUTATION = gql`
  mutation SetManualControlValues(
    $siteId: ID!
    $values: IManualControlValuesInput!
  ) {
    setManualControlValues(siteId: $siteId, values: $values)
  }
`;

export const SET_HEATING_SETTINGS_MUTATION = gql`
  mutation SetHeatingSettings(
    $siteId: ID!
    $settings: IHeatingSettingsInput!
  ) {
    setHeatingSettings(siteId: $siteId, settings: $settings)
  }
`;

export const SET_CONSUMPTION_SCHEDULE_MUTATION = gql`
  mutation SetConsumptionSchedule(
    $siteId: ID!
    $scheduleId: String!
    $days: [IConsumptionScheduleDayInput!]!
    $noSuggestions: Boolean!
  ) {
    setConsumptionSchedule(siteId: $siteId, scheduleId: $scheduleId, days: $days, noSuggestions: $noSuggestions)
  }
`;

export const SET_SETTINGS_MUTATION = gql`
  mutation SetSettings($siteId: ID!, $settings: ISettingsInput!, $mode: String!) {
    setSettings(siteId: $siteId, settings: $settings, mode: $mode)
  }
`;

export const ACK_ALARMS_MUTATION = gql`
  mutation AckAlarms($siteId: ID!) {
    ackAlarms(siteId: $siteId)
  }
`;

export const PURGE_ALARMS_MUTATION = gql`
  mutation PurgeAlarms($siteId: ID!) {
    purgeAlarms(siteId: $siteId)
  }
`;

export const USER_PREFERENCE_MUTATION = gql`
  mutation UserPreferenceMutation(
    $name: String!
    $value: UserPreferenceType!
  ) {
    userPreference(name: $name, value: $value)
  }
`;

export const EXPORT_DATA_MUTATION = gql`
  mutation ExportDataMutation(
    $siteId: ID!
    $exports: [Boolean!]!
    $timePeriod: String!
    $timeResolution: String!
    $fileFormat: String!
  ) {
    exportData(siteId: $siteId, exports: $exports, timePeriod: $timePeriod, timeResolution: $timeResolution, fileFormat: $fileFormat) {
      url
    }
  }
`;
