import React, { FunctionComponent } from "react";
import { IconButton, useDisclosure } from "@chakra-ui/react";
import { SettingsIcon } from "@chakra-ui/icons";

import {
  SiteConfig_siteConfig_manualControlConf,
} from "graphql/generated";

import PowerControlModal from "./Modal";


interface IProps {
  siteId: string;
  manualControlConf: SiteConfig_siteConfig_manualControlConf;
}


const PowerControlSettings: FunctionComponent<IProps> = ({
  siteId,
  manualControlConf,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return <>
    {isOpen && (
      <PowerControlModal
        siteId={siteId}
        manualControlConf={manualControlConf}
        onClose={onClose}
      />
    )}

    <IconButton
    aria-label="Settings"
    colorScheme="yellow"
    size="sm"
    onClick={onOpen}
    icon={<SettingsIcon />}
    />
  </>;
}

export default PowerControlSettings;
