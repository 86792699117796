import React, { FunctionComponent } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  Spinner,
  Center,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { useQuery } from "@apollo/client";

import {
  SiteConfig_siteConfig_manualControlConf,
  GetManualControlValues,
  GetManualControlValuesVariables,
} from "graphql/generated";
import { GET_MANUAL_CONTROL_VALUES_QUERY } from "graphql/queries";

import PowerControlModalContent from "./ModalInside";


interface IProps {
  siteId: string;
  manualControlConf: SiteConfig_siteConfig_manualControlConf;
  onClose: () => void;
}


const PowerControlModal: FunctionComponent<IProps> = ({
  siteId,
  manualControlConf,
  onClose,
}) => {
  const intl = useIntl();

  const { data, loading } = useQuery<
    GetManualControlValues,
    GetManualControlValuesVariables
  >(GET_MANUAL_CONTROL_VALUES_QUERY, { variables: { siteId } });

  return <>
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {intl.formatMessage({
            id: "modals__controls____title",
            defaultMessage: "Manual control",
          })}
        </ModalHeader>
        {!loading && <ModalCloseButton />}
        {data && !loading ? (
          <PowerControlModalContent
            siteId={siteId}
            manualControlConf={manualControlConf}
            values={data.getManualControlValues}
            onClose={onClose}
          />
        ) : (
          <Center mb={10} mt={8}>
            <Spinner size="xl" />
          </Center>
        )}
      </ModalContent>
    </Modal>
  </>;
};

export default PowerControlModal;
