import React, { useEffect, useState, useCallback, useMemo } from "react";
import { defer } from "lodash";

import {
  Button,
  Input,
  InputGroup,
  InputProps,
  LayoutProps,
  SpaceProps,
} from "@chakra-ui/react";

import { TNumericValue } from "./types";


interface NumberInputProps extends InputProps, SpaceProps, LayoutProps {
  currentValue: number;
  originalValue: number;
  setValue: (newValue: number) => void;
  decimals?: number;
  step?: number;
  unit?: string;
}


export const displayNumberDecimals = (value: TNumericValue, decimals: number = 1): string => {
  if (value === undefined || value === null) return "";
  return Number.isInteger(value) ? value.toString() : value.toFixed(decimals)
}


const NumberInput: React.FC<NumberInputProps> = ({
  currentValue,
  originalValue,
  setValue,
  decimals = 1,
  step,
  unit,
  ...rest
}) => {
  const [displayValue, setDisplayValue] = useState<string>("");
  const inputPattern = useMemo(() => /^-?[0-9]*(|\.|\.[0-9]+)$/, []);

  const adjustedDecimals = useCallback((inputValue: string): number => {
      const inputDecimals = inputValue.length - inputValue.indexOf(".") - 1;
      const minDecimals = Math.min(decimals, inputDecimals);

      return minDecimals;
  }, [decimals]);

  useEffect(() => {
    if (typeof currentValue === "number") {
      setDisplayValue(displayNumberDecimals(currentValue, adjustedDecimals(currentValue.toString())));
    } else {
      setDisplayValue("");
    }
  }, [currentValue, adjustedDecimals]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const commaTypedLast = event.target.value.includes(",");
    const inputValue = event.target.value.replace(",", ".");
    const parsedValue = parseFloat(inputValue);

    if (inputValue === "") { // Allow empty deleted value during typing
      setDisplayValue(inputValue);
      return;
    }
    if (!inputValue.match(inputPattern)) { // Ignore malformed number
      return;
    }
    if (isNaN(parsedValue)) { // Ignore non-numeric input
      return;
    }
    if (inputValue.endsWith(".")) { // Allow unfinished number during typing
      setDisplayValue(inputValue);
      return;
    }
    if (inputValue.endsWith(".0")) { // Allow float-like integer during typing
      setDisplayValue(inputValue);
      return;
    }
    if (Math.abs(parsedValue) > Number.MAX_SAFE_INTEGER) { // Ignore unsafe numbers
      return;
    }
    if (Number.isInteger(parsedValue)) {
      setDisplayValue(parsedValue.toString());
      setValue(parsedValue);
    } else {
      const useDecimals = adjustedDecimals(inputValue);

      setDisplayValue(parsedValue.toFixed(useDecimals));
      if (parsedValue.toFixed(useDecimals) !== originalValue.toFixed(useDecimals)) {
        setValue(parsedValue);
      } else {
        // If the new value is equivalent to the PLC's messy original, use the original
        setValue(originalValue);
      }
    }
    if (commaTypedLast) { // Restore cursor position after comma replacement
      const decimalPointPosition = displayValue.indexOf(".") + 1;

      defer(() => event.target.setSelectionRange(decimalPointPosition, decimalPointPosition));
    }
  }, [inputPattern, adjustedDecimals, originalValue, displayValue, setValue]);

  const handleBlur = () => {
    if (displayValue.endsWith(".0")) { // Make x.0 float an integer
      setValue(parseFloat(displayValue));
    }
    if (typeof currentValue === "number") {
      setDisplayValue(displayNumberDecimals(currentValue, decimals));
    } else {
      setDisplayValue("");
    }
  }

  const numericValueStep = (unit?: string): number => {
    switch(unit) {
      case 'V': return 0.1;
      default: return 1;
    }
  }

  const stepDown = () => {
    setValue(currentValue - (step || numericValueStep(unit)));
  }

  const stepUp = () => {
    setValue(currentValue + (step || numericValueStep(unit)));
  }

  return (
    <InputGroup>
      <Button size="sm" onClick={stepDown}>-</Button>
      <Input
        type="text"
        value={displayValue}
        onChange={handleChange}
        onBlur={handleBlur}
        pattern={inputPattern.toString()}
        inputMode="decimal"
        {...rest}
      />
      <Button size="sm" onClick={stepUp}>+</Button>
    </InputGroup>
  );
}

export default NumberInput;
