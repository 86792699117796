import React, { FunctionComponent } from "react";
import { Badge, Skeleton } from "@chakra-ui/react";
import { useIntl } from "react-intl";


interface IProps {
  medium: string;
  volume: number;
}


const HeatingTankMedium: FunctionComponent<IProps> = ({ medium, volume }) => {
  const intl = useIntl();

  switch (medium) {
    case "water":
      return (
        <>
          <Badge variant={"outline"} colorScheme={"gray"} mr={3}>
            {intl.formatMessage({
              id: "heating_tank__medium__water",
              defaultMessage: "WATER",
            })}
          </Badge>
          {volume}
        </>
      )
    case "glycol":
      return (
        <>
          <Badge variant={"outline"} colorScheme={"gray"} mr={3}>
            {intl.formatMessage({
              id: "heating_tank__medium__glycol",
              defaultMessage: "GLYCOL",
            })}
          </Badge>
          {volume}
        </>
      )
    case "termofrost-e-12":
      return (
        <>
          <Badge variant={"outline"} colorScheme={"gray"} mr={3}
            title={intl.formatMessage({
              id: "heating_tank__medium__termofrost-e-12_tooltip",
              defaultMessage: "Termofrost E",
            })}
          >
            {intl.formatMessage({
              id: "heating_tank__medium__termofrost-e-12",
              defaultMessage: "TF E",
            })}
          </Badge>
          {volume}
        </>
      )
    default:
      return (
        <Skeleton minW={5} w={10} h={3} />
      )
  }
};

export default HeatingTankMedium;
